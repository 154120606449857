import moment from 'moment';

export const CONTROL_TYPES = Object.freeze({
    ORDERS: 'ORDERS',
    RIDERS: 'RIDERS',
    ALERTS: 'ALERTS',
    MAP_VIEW: 'MAP_VIEW',
    COMMON_FILTER: 'COMMON_FILTER',
});
export const CONTROL_TABS = [
    {
        type: CONTROL_TYPES.ORDERS,
        label: 'ORDERS',
        icon: 'dashboard',
        show: true,
    },
    {
        type: CONTROL_TYPES.RIDERS,
        label: 'RIDERS',
        icon: 'RIDERS',
        show: true,
    },
    {
        type: CONTROL_TYPES.ALERTS,
        label: 'ALERTS',
        icon: 'ALERTS',
        show: true,
    },
    {
        type: CONTROL_TYPES.MAP_VIEW,
        label: 'MAP VIEW',
        icon: 'MAP_VIEW',
        show: false,
    },
];
export function getControlsTabProps(index: any, tab?: any) {
    return {
        id: `control-center-tab-${index}`,
        'aria-controls': `control-center-tab-${index}`,
        label: tab?.label,
    };
}

export const CONTROL_ORDERS_FILTERS = Object.freeze({
    TOTAL: 'TOTAL',
    ON_DUTY: 'ON_DUTY',
    LIVE: 'LIVE',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    MISSED: 'MISSED',
    RTO_COMPLETED: 'RTO_COMPLETED',
    SLA_BREACH: 'SLA_BREACH',
    ALERTS: 'ALERTS',
    AVERAGE_O2A_COUNT: 'AVERAGE_O2A_COUNT',
    ALL: 'ALL',
    ONLINE_ON_DUTY_FREE: 'ONLINE_ON_DUTY_FREE',
    OFFLINE: 'OFFLINE',
    ON_BREAK: 'ON_BREAK',
    OPEN: 'OPEN',
    ASSIGNED: 'ASSIGNED',
    RESOLVED: 'RESOLVED',
    NOT_RESOLVED: 'NOT_RESOLVED',
    IN_STORE: 'IN_STORE',
    TO_CX: 'TO_CX',
    TO_DX: 'TO_DX',
    CX_HAND_OVER: 'CX_HAND_OVER',
    ONLINE: 'ONLINE',
    SYSTEM: 'SYSTEM',
    MANUAL: 'MANUAL',
});
export const STATUS_TYPES = Object.freeze({
    NOT_RESOLVE: 'NOT_RESOLVED',
    RESOLVE: 'RESOLVED',
    ASSIGN: 'ASSIGNED',
    COMMENT: 'COMMENT',
});
export const STATUS_TYPES_CONFIG: any = Object.freeze({
    [STATUS_TYPES.NOT_RESOLVE]: {
        name: 'Not Resolved',
        actionName: '',
    },
    [STATUS_TYPES.RESOLVE]: {
        name: 'Resolved',
        actionName: '',
    },
    [STATUS_TYPES.ASSIGN]: {
        name: 'Assigned',
        actionName: '',
    },
    [STATUS_TYPES.COMMENT]: {
        name: 'Comment',
        actionName: '',
    },
});
export const ALERT_STATUS_UPDATE = Object.freeze([
    STATUS_TYPES.NOT_RESOLVE,
    STATUS_TYPES.ASSIGN,
    STATUS_TYPES.RESOLVE,
]);
export const CONTROL_ORDERS_FILTER_CARDS = [
    {
        name: 'Total',
        type: CONTROL_ORDERS_FILTERS.TOTAL,
        filterValue: {
            type: 'Total', // Total, Live, Completed, Cancelled, SLA_Breach, Alerts
            subType: '',
        },
        valueMappingKey: 'totalCount',
    },
    {
        name: 'Live',
        type: CONTROL_ORDERS_FILTERS.LIVE,
        filterValue: {
            type: 'Live', // Live, Completed, Cancelled, SLA_Breach, Alerts
            subType: '',
        },
        xs: 3,
        valueMappingKey: 'liveCount',
        subFilters: [
            {
                name: 'In store',
                type: CONTROL_ORDERS_FILTERS.IN_STORE,
                filterValue: {
                    type: 'Live', // Total, Live, Completed, Cancelled, SLA_Breach, Alerts
                    subType: 'In store',
                },
                valueMappingKey: 'inStoreCount',
            },
            {
                name: 'To CX',
                type: CONTROL_ORDERS_FILTERS.TO_CX,
                filterValue: {
                    type: 'Live', // Total, Live, Completed, Cancelled, SLA_Breach, Alerts
                    subType: 'To CX',
                },
                valueMappingKey: 'toCXCount',
            },
            {
                name: 'CX handover',
                type: CONTROL_ORDERS_FILTERS.CX_HAND_OVER,
                filterValue: {
                    type: 'Live', // Total, Live, Completed, Cancelled, SLA_Breach, Alerts
                    subType: 'CX handover',
                },
                valueMappingKey: 'cxHandoverCount',
            },
            {
                name: 'RTO',
                type: CONTROL_ORDERS_FILTERS.CX_HAND_OVER,
                filterValue: {
                    type: 'Live', // Total, Live, Completed, Cancelled, SLA_Breach, Alerts
                    subType: 'RTO',
                },
                valueMappingKey: 'rtoCount',
            },
        ],
    },
    {
        name: 'Completed',
        type: CONTROL_ORDERS_FILTERS.COMPLETED,
        filterValue: {
            type: 'Completed',
            subType: '',
        },
        valueMappingKey: 'completedCount',
    },
    {
        name: 'Cancelled',
        type: CONTROL_ORDERS_FILTERS.CANCELLED,
        filterValue: {
            type: 'Cancelled',
            subType: '',
        },
        valueMappingKey: 'cancelledCount',
    },
    {
        name: 'Missed',
        type: CONTROL_ORDERS_FILTERS.MISSED,
        filterValue: {
            type: 'Missed',
            subType: '',
        },
        valueMappingKey: 'missedOrderCount',
        filterDisabled: false,
    },
    {
        name: 'RTO',
        type: CONTROL_ORDERS_FILTERS.RTO_COMPLETED,
        filterValue: {
            type: 'RTO Completed',
            subType: '',
        },
        valueMappingKey: 'rtoCompletedCount',
    },
    {
        name: 'SLA Breach',
        type: CONTROL_ORDERS_FILTERS.SLA_BREACH,
        filterValue: {
            type: 'SLA_Breach',
            subType: '',
        },
        valueMappingKey: 'slaBreach',
        filterDisabled: true,
    },
    {
        name: 'Alerts',
        type: CONTROL_ORDERS_FILTERS.ALERTS,
        filterValue: {
            type: 'Alerts',
            subType: '',
        },
        valueMappingKey: 'alerts',
    },
    {
        name: 'Avg O2A',
        type: CONTROL_ORDERS_FILTERS.ALERTS,
        filterValue: {
            type: 'averageO2ACount',
            subType: '',
        },
        valueMappingKey: 'averageO2ACount',
        filterDisabled: true,
    },
];
export const CHIPS_CLASS_TYPE_MAPPING: any = Object.freeze({
    TO_CX: 'active',
    IN_STORE: 'pending',
    COMPLETED: 'active',
    ON_ORDER: 'active',
    NO_ORDER: 'none',
    ON_BREAK: 'none',
    TO_DX: 'pending',
    OPEN: 'pending',
    RESOLVED: 'active',
    NOT_RESOLVED: 'reject',
    ASSIGNED: 'inactive',
    ARRIVED_AT_PICKUP_LOCATION: 'inactive',
    ARRIVED_AT_DELIVERY_LOCATION: 'inactive',
    FREE: 'pending',
    PICKEDUP: 'inactive',
    INITIATED: 'inactive',
});
export const CHIPS_CLASS_TYPE_ORDERS_STATUS_MAPPING: any = Object.freeze({
    TO_CX: 'none2',
    IN_STORE: 'pending',
    COMPLETED: 'active',
    CANCELLED: 'reject',
    RTO: 'reject',
    TO_DX: 'none2',
    OPEN: 'pending',
    CX_HANDOVER: 'inactive',
    ARRIVED_AT_PICKUP_LOCATION: 'inactive',
    ARRIVED_AT_DELIVERY_LOCATION: 'inactive',
    RESOLVED: 'active',
    NOT_RESOLVED: 'reject',
    ASSIGNED: 'inactive',
    INITIATED: 'inactive',
});
export const CHIPS_CLASS_TYPE_RIDERS_STATUS_MAPPING: any = Object.freeze({
    TO_CX: 'activeOutlined',
    IN_STORE: 'pendingOutlined',
    COMPLETED: 'active',
    ON_DUTY: 'activeFill',
    IN_QUEUE: 'activeOutlined',
    FREE: 'pendingFill',
    NO_ORDER: 'none',
    ON_BREAK: 'none',
    TO_DX: 'pendingOutlined',
    TO_DX_FREE: 'pendingOutlined',
    IN_QUEUE_FREE: 'pendingOutlined',
    IN_STORE_FREE: 'pendingOutlined',
    OPEN: 'pending',
    RESOLVED: 'active',
    NOT_RESOLVED: 'reject',
    ASSIGNED: 'inactive',
    ARRIVED_AT_PICKUP_LOCATION: 'inactive',
    ARRIVED_AT_DELIVERY_LOCATION: 'inactive',
    PICKEDUP: 'inactive',
    CX_HANDOVER: 'activeOutlined',
    RTO: 'activeOutlined',
});

export const CONTROL_RIDERS_FILTER_CARDS = [
    {
        name: 'Total',
        type: CONTROL_ORDERS_FILTERS.TOTAL,
        filterValue: {
            type: 'Total',
            subType: '',
        },
        valueMappingKey: 'totalCount',
    },
    {
        name: '',
        type: CONTROL_ORDERS_FILTERS.ONLINE_ON_DUTY_FREE,
        filterValue: {
            type: 'Online',
            subType: '',
        },
        sections: [
            {
                name: 'Online',
                type: CONTROL_ORDERS_FILTERS.ONLINE,
                filterValue: {
                    type: 'Online',
                    subType: '',
                },
                valueMappingKey: 'onlineCount',
            },
            {
                name: 'On Duty',
                type: CONTROL_ORDERS_FILTERS.ON_DUTY,
                filterValue: {
                    type: 'OnDuty',
                    subType: '',
                },
                valueMappingKey: 'onDutyCountValue',
                subValueMappingKey: 'onDutyCount',
                subFilters: [
                    {
                        name: 'In Queue',
                        type: CONTROL_ORDERS_FILTERS.IN_STORE,
                        filterValue: {
                            type: 'OnDuty',
                            subType: 'In queue',
                        },
                        valueMappingKey: 'inQueueCount',
                        mW: '65px',
                    },
                    {
                        name: 'To CX',
                        type: CONTROL_ORDERS_FILTERS.TO_CX,
                        filterValue: {
                            type: 'OnDuty',
                            subType: 'To CX',
                        },
                        valueMappingKey: 'toCXCount',
                    },
                    {
                        name: 'CX handover',
                        type: CONTROL_ORDERS_FILTERS.CX_HAND_OVER,
                        filterValue: {
                            type: 'OnDuty',
                            subType: 'CX handover',
                        },
                        valueMappingKey: 'cxHandoverCount',
                        mW: '88px',
                    },
                    {
                        name: 'RTO',
                        type: CONTROL_ORDERS_FILTERS.CX_HAND_OVER,
                        filterValue: {
                            type: 'OnDuty',
                            subType: 'RTO',
                        },
                        valueMappingKey: 'rtoCount',
                    },
                ],
            },
            {
                name: 'Free',
                type: CONTROL_ORDERS_FILTERS.ON_DUTY,
                filterValue: {
                    type: 'Free',
                    subType: '',
                },
                valueMappingKey: 'freeCountValue',
                subValueMappingKey: 'freeCount',
                subFilters: [
                    {
                        name: 'In Store',
                        type: CONTROL_ORDERS_FILTERS.IN_STORE,
                        filterValue: {
                            type: 'Free',
                            subType: 'In store',
                        },
                        valueMappingKey: 'inStoreCount',
                    },
                    {
                        name: 'In Queue',
                        type: CONTROL_ORDERS_FILTERS.IN_STORE,
                        filterValue: {
                            type: 'Free',
                            subType: 'In queue',
                        },
                        valueMappingKey: 'inQueueCount',
                    },
                    {
                        name: 'To DX',
                        type: CONTROL_ORDERS_FILTERS.TO_DX,
                        filterValue: {
                            type: 'Free',
                            subType: 'To DX',
                        },
                        valueMappingKey: 'toDXCount',
                    },
                ],
            },
        ],
    },
    {
        name: 'Offline',
        type: CONTROL_ORDERS_FILTERS.OFFLINE,
        filterValue: {
            type: 'Offline',
            subType: '',
        },
        valueMappingKey: 'offlineCount',
    },
    {
        name: 'Avg Wait Time',
        type: CONTROL_ORDERS_FILTERS.ON_BREAK,
        filterValue: {
            type: 'avhWaitTime',
            subType: '',
        },
        valueMappingKey: 'averageWaitTimeMins',
        filterDisabled: true,
    },
    {
        name: 'Alerts',
        type: CONTROL_ORDERS_FILTERS.ALERTS,
        filterValue: {
            type: 'Alerts',
            subType: '',
        },
        valueMappingKey: 'alerts',
    },
];
export const CONTROL_ALERTS_FILTER_CARDS = [
    {
        name: 'Total',
        type: CONTROL_ORDERS_FILTERS.TOTAL,
        filterValue: {
            type: 'Total',
            subType: '',
        },
        valueMappingKey: 'totalCount',
    },
    {
        name: 'Open',
        type: CONTROL_ORDERS_FILTERS.OPEN,
        filterValue: {
            type: 'Open',
            subType: '',
        },
        valueMappingKey: 'openCount',
    },
    {
        name: 'Assigned',
        type: CONTROL_ORDERS_FILTERS.ASSIGNED,
        filterValue: {
            type: 'Assigned',
            subType: '',
        },
        valueMappingKey: 'assignedCount',
    },
    {
        name: 'Resolved',
        type: CONTROL_ORDERS_FILTERS.RESOLVED,
        filterValue: {
            type: 'Resolved',
            subType: '',
        },
        valueMappingKey: 'resolvedCountValue',
        subValueMappingKey: 'resolvedCount',
        subFilters: [
            {
                name: 'System',
                type: CONTROL_ORDERS_FILTERS.SYSTEM,
                filterValue: {
                    type: 'Resolved', // Total, Live, Completed, Cancelled, SLA_Breach, Alerts
                    subType: 'System',
                },
                valueMappingKey: 'system',
            },
            {
                name: 'Manual',
                type: CONTROL_ORDERS_FILTERS.MANUAL,
                filterValue: {
                    type: 'Resolved', // Total, Live, Completed, Cancelled, SLA_Breach, Alerts
                    subType: 'Manual',
                },
                valueMappingKey: 'manual',
            },
        ],
    },
    {
        name: 'Not Resolved',
        type: CONTROL_ORDERS_FILTERS.NOT_RESOLVED,
        filterValue: {
            type: 'NotResolved',
            subType: '',
        },
        valueMappingKey: 'notResolvedCount',
    },
];

export const preProcessThePayload = (payload: any, defaultValue?: any) => {
    return {
        orgIds: payload?.orgIds ?? [],
        dateRange: {
            fromEpochMillis: moment(payload?.selectedDate ?? moment().valueOf())
                ?.startOf('day')
                ?.valueOf(),
            toEpochMillis: moment(payload?.selectedDate ?? moment().valueOf())
                ?.endOf('day')
                ?.valueOf(),
        },
        hubCodes:
            payload?.hubCodes
                ?.map((hub: any) => hub?.value)
                ?.filter((value: any) => !!value && value != null) ??
            defaultValue?.hubCodes ??
            [],
        clusterIds:
            payload?.clusterIds
                ?.map((cluster: any) => cluster?.value)
                ?.filter((value: any) => !!value && value != null) ??
            defaultValue?.clusterIds ??
            [],
        storeIds:
            payload?.hubCodes
                ?.map((hub: any) => hub?.storeId)
                ?.filter((value: any) => !!value && value != null) ?? [],
        shiftId:
            payload?.shiftId
                ?.map((shift: any) => shift?.value)
                ?.filter((value: any) => !!value && value != null) ?? [],
        pageNo: payload?.pageNo ?? 1,
        pageSize: payload?.pageSize ?? 10,
        filters: payload?.filters ?? {},
        searchText: payload?.searchText ?? '',
        ...(payload?.shiftId?.[0]?.startTimeEpochMillis &&
        payload?.shiftId?.[0]?.endTimeEpochMillis
            ? {
                  shiftStartTime: payload?.shiftId?.[0]?.startTimeEpochMillis,
                  shiftEndTime: payload?.shiftId?.[0]?.endTimeEpochMillis,
              }
            : {}),
    };
};
type RemoveKeysFromObject = <T extends object>(
    obj: T,
    keysToRemove: (keyof T)[],
) => Partial<T>;

export const removeKeysFromObject: RemoveKeysFromObject = (
    obj,
    keysToRemove,
) => {
    if (keysToRemove.length === 0) {
        return obj; // Return the original object if no keys to remove
    }
    const newObj = { ...obj };
    keysToRemove.forEach((key) => {
        delete newObj?.[key];
    });
    return newObj;
};
export const getShiftLabel = (data: any) => {
    if (!!data?.shortName && !!data?.startTime && !!data?.endTime) {
        return `${data?.shortName} (${data?.startTime}-${data?.endTime})`.toUpperCase();
    }
    return data?.shortName ?? data?.name ?? '';
};
export const replaceWithUnderscore = (input: string): string => {
    // Use regex to replace spaces and special characters
    return input?.replace(/[^a-zA-Z0-9]/g, '_')?.trim() ?? '';
};
export const replaceWithSpace = (input: string): string => {
    // Use regex to replace spaces and special characters with a single space
    return input?.replace(/[^a-zA-Z0-9]/g, ' ')?.trim() ?? '';
};
export function isFirstVisitToday() {
    // Get today's date in "YYYY-MM-DD" format
    const today = new Date()?.toISOString()?.split('T')?.[0];

    // Retrieve the last visit date from localStorage
    const lastVisit = localStorage.getItem('lastVisitDate');

    // If no last visit date found, or the last visit was on a different day
    if (!lastVisit || lastVisit !== today) {
        // Store today's date in localStorage as the last visit
        localStorage.setItem('lastVisitDate', today);

        // It's the first visit today
        return true;
    }

    // If the date matches today's date, it's not the first visit today
    return false;
}
